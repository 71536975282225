import { formatPriceParams } from '../../types';
/**
 *
 * @param params object which passes 'min', 'max', 'unit' from price
 * and 'language', 'country' from store response in order to set local properly.
 * @param minOrMax 'min' or 'max' -
 * defining which price property do we need to format and return.
 * @returns formatted price string, for example: 123.456,79 €
 */
export const formatPrice = (params: formatPriceParams, minOrMax: string): string => {
  if (params.unit === 'SGD') {
    const passedPrice = Number(minOrMax === 'min' ? params.min : params.max);
    return customNumberPriceFormat(passedPrice, params.unit, ',', '.');
  }

  return Intl.NumberFormat(`${params.language}-${params.country}`, {
    style: 'currency',
    currency: `${params.unit}`,
  }).format(Number(minOrMax === 'min' ? params.min : params.max));
};

const customNumberPriceFormat = (num: number, unit: string, thousandsSeparator = '.', decimalsSeparator = ',') => {
  const [integer, decimal = '00'] = num.toFixed(2).split('.');
  return `${integer.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)}${decimalsSeparator}${decimal} ${unit}`;
};
