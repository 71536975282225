import { VMDS_ENDPOINT_URL } from '../../consts';
import { VmdsUrlParams } from '../../types';
import { HighlightGroups } from '../../types';

export const makeVmdsServiceUrl = (
  apiUrl: string,
  { country, language, modelSeries, engineConcept, subBrands }: VmdsUrlParams,
): string => {
  //there will be a request to support VANS usecases as well - therefore currently hardcoded, since out of scope
  const highlightGroupKey = HighlightGroups.Cars;
  const url = new URL(`${apiUrl}${VMDS_ENDPOINT_URL}${country}/${language}/${highlightGroupKey}/${modelSeries}`);

  for (const subBrand of subBrands) {
    url.searchParams.append('subBrands', subBrand);
  }

  if (engineConcept) {
    url.searchParams.append('engineConcept', engineConcept);
  }

  return url.toString();
};
