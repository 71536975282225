import { DeeplinkUrlParams } from '../../types';
import { DeeplinkCmsInstance } from '../../enums';

export const makeDeepLinkServiceUrl = (
  apiUrl: string,
  { runMode, country, language, modelSeries, subBrands }: DeeplinkUrlParams,
): string => {
  const cmsInstance = runMode === 'AUTHOR' ? DeeplinkCmsInstance.Author : DeeplinkCmsInstance.Publish;
  const searchParams = new URLSearchParams([
    ...(subBrands || []).map((subBrand: string) => ['subBrands', subBrand]),
    ['cmsInstance', cmsInstance],
  ]);
  return `${apiUrl}/vehicle-deeplinks-api/v1/deeplinks/${country}/${language}/model-series/${modelSeries}?${searchParams}`;
};
