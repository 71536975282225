import { initializeLogger } from '@seamless/logger';
import { SERVICE_NAMESPACE, DEEPLINK_MOCK_DATA_STORAGE_KEY } from '../../consts';
import { DeeplinkResponse } from '../../types';

const logger = initializeLogger(SERVICE_NAMESPACE);

// Data mocking is designed for QA to make sure all the scenarios are covered.
// In the time of developing the CTA service, not all scenarios are returned from deep link service,
// which means we need to provide a possibility to test every specific case.
const getMockData = (): DeeplinkResponse | undefined => {
  try {
    const storedMockData = localStorage.getItem(DEEPLINK_MOCK_DATA_STORAGE_KEY);
    if (storedMockData) {
      const mockData = JSON.parse(storedMockData) as DeeplinkResponse;
      logger.log(
        'Skipping sending request to the deeplink service and using mock data from the local storage:',
        mockData,
      );
      return mockData;
    }
  } catch (error: any) {
    logger.error('An error occurred during injecting deep link data from the local storage:', error.message);
  }
};

export const loadDataFromDeeplinkService = async (url: string): Promise<DeeplinkResponse | undefined> => {
  const mockData = getMockData();
  if (mockData) {
    return mockData;
  }

  try {
    logger.log(`Sending request to ${url}`);
    const response = await fetch(url);
    const data = await response.json();
    if (response.ok) {
      return data;
    }
  } catch (error) {
    throw Error('Error caused during processing request to deep link service');
  }
};
