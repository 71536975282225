import { DeeplinkResponse } from '../../types';
import { DeeplinkKeys, ScenarioKeys } from '../../enums';

// All deeplink response scenarios are valid for both use cases, home and PI/PAD
const fullScenario = [DeeplinkKeys.Configurator, DeeplinkKeys.OnlineShop];
const noShopScenario = [DeeplinkKeys.Configurator];
const noCarConfiguratorScenario = [DeeplinkKeys.OnlineShop, DeeplinkKeys.ProductPage];
const noShopAndCarConfiguratorScenario = [DeeplinkKeys.ProductPage];

const validateDeeplinkResponse = (response: DeeplinkResponse, ctaScenario: DeeplinkKeys[]): boolean =>
  ctaScenario.every((key: DeeplinkKeys) => Object.keys(response).includes(key));

export const assignCtaScenario = (
  response: DeeplinkResponse,
  productPageContext = false,
  scenario = ScenarioKeys.Default,
): DeeplinkKeys[] => {
  switch (true) {
    case validateDeeplinkResponse(response, fullScenario):
      return productPageContext
        ? assignCTAAlternativeScenario(scenario)
        : [DeeplinkKeys.OnlineShop, DeeplinkKeys.ProductPage];
    case validateDeeplinkResponse(response, noShopScenario):
      return productPageContext
        ? [DeeplinkKeys.Configurator, DeeplinkKeys.Enquiry]
        : [DeeplinkKeys.Configurator, DeeplinkKeys.ProductPage];
    case validateDeeplinkResponse(response, noCarConfiguratorScenario):
      return productPageContext ? [DeeplinkKeys.OnlineShop] : [DeeplinkKeys.OnlineShop, DeeplinkKeys.ProductPage];
    case validateDeeplinkResponse(response, noShopAndCarConfiguratorScenario):
      return productPageContext ? [DeeplinkKeys.Enquiry] : [DeeplinkKeys.ProductPage];
  }
  return [];
};

const assignCTAAlternativeScenario = (scenario: string): DeeplinkKeys[] => {
  return scenario === ScenarioKeys.Default
    ? [DeeplinkKeys.OnlineShop, DeeplinkKeys.Configurator]
    : [DeeplinkKeys.OnlineShop, DeeplinkKeys.Enquiry];
};
