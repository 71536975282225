import { ConnectionResponse, formatPriceParams, VmdsHighlightKeys, VmdsResponse } from '../../types';
import { formatPrice } from '../formatPrice/formatPrice';

export const formatResponse = (data: VmdsResponse, language: string, country: string): ConnectionResponse => {
  const i18n = data.i18n;
  const price = data.highlights[VmdsHighlightKeys.Price];
  const { unit, footnoteI18nKey } = price;
  const priceParams: formatPriceParams = {
    min: String(price.min),
    max: String(price.max),
    unit: price.unit,
    language,
    country,
  };
  const min = formatPrice(priceParams, 'min');
  const max = formatPrice(priceParams, 'max');
  const response: ConnectionResponse = {
    unit,
    min,
    max,
    pricePrefixLabel: i18n.PRICE,
  };

  if (footnoteI18nKey && i18n[footnoteI18nKey]) {
    response.priceFootnoteLabel = i18n[footnoteI18nKey];
  }

  return response;
};
