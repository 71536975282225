import { initializeCtaAutomationService } from './packages/cta-automation';
import { initializePriceAutomationService } from './packages/price-automation';
import { provideDataFromVehicleDataStoreOnce } from './utils';

export { loadCtaConfiguration } from './packages/cta-automation';
export { loadPriceData } from './packages/price-automation';

(async () => {
  const { modelSeries } = await provideDataFromVehicleDataStoreOnce();

  // When model series exists in the store that means the code is being executed on a vehicle context page which means
  // automatic initialization of services is needed to fill out the store with all required data like CTA or pricing.
  if (modelSeries) {
    initializeCtaAutomationService();
    initializePriceAutomationService();
  }
})();
