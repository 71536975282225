import { CTAFormatted, DeeplinkResponse } from '../../types';
import { CTAKeys, DeeplinkKeys } from '../../enums';

export const mapResponse = (response: DeeplinkResponse, ...deepLinkKeys: DeeplinkKeys[]): CTAFormatted | undefined => {
  // We can reorder the array to match the deepLinkKeys
  const ctaKeys = [
    CTAKeys.Primary,
    CTAKeys.Secondary,
    CTAKeys.Shop,
    CTAKeys.Configurator,
    CTAKeys.Info,
    CTAKeys.Enquiry,
    CTAKeys.Informed,
  ];

  // Skip if no deeplink keys provided
  if (!deepLinkKeys.length) {
    return;
  }

  const mapped = deepLinkKeys.reduce((ctaConfig, deepLinkKey, index) => {
    const ctaKey = ctaKeys[index];
    const deepLinkData = response[deepLinkKey];

    if (ctaKey && deepLinkData) {
      const ctaWithType = {
        ...deepLinkData,
        type: deepLinkKey,
      };
      ctaConfig[ctaKey] = ctaWithType;
    }

    return ctaConfig;
  }, {} as CTAFormatted);

  if (Object.keys(mapped).length) {
    return mapped;
  }
};
