import { initializeLogger } from '@seamless/logger';
import { getConnectionVehicleDataDispatchers } from '@dh-io-mbmxp/vehicle-data-store-connection';
import { SERVICE_NAMESPACE } from './consts';
import { LoadCTAConfigurationFunction } from './types';
import { DeeplinkKeys, ScenarioKeys } from './enums';
import { assignCtaScenario, loadDataFromDeeplinkService, mapResponse, makeDeepLinkServiceUrl } from './utils';
import { provideDataFromAemEnvStoreOnce, provideDataFromVehicleDataStoreByCallback } from '../../utils';

const logger = initializeLogger(SERVICE_NAMESPACE);

export const initializeCtaAutomationService = (): void =>
  provideDataFromVehicleDataStoreByCallback(async ({ modelSeries, subBrands, cta = {} }, unsubscribe) => {
    const { apiUri, runMode, country, language, vehicleData } = await provideDataFromAemEnvStoreOnce();
    const { informed, enquiry } = cta;
    let CtaScenarioKey = ScenarioKeys.Default;

    if (!apiUri || !modelSeries) {
      logger.log('No apiUri or modelSeries found, skipping CTA service initialization');
      return;
    }

    try {
      const deepLinkUrl = makeDeepLinkServiceUrl(apiUri, {
        runMode,
        country,
        language,
        modelSeries,
        subBrands,
      });

      let data = await loadDataFromDeeplinkService(deepLinkUrl);

      if (!data) {
        logger.log(`No data loaded from deep link service for ${modelSeries}`);
        return;
      }

      logger.log(`Data for ${modelSeries} has been loaded:`, data);

      data = {
        ...data,
        [DeeplinkKeys.Enquiry]: enquiry,
        [DeeplinkKeys.Informed]: informed,
      };

      if (vehicleData?.cta) {
        const tempCtaData: any = vehicleData.cta; //Need to have type any on vehicleData.cta as it's unknown and is throwing an error (you cannot check against unknown in TS)
        CtaScenarioKey = tempCtaData.scenario as ScenarioKeys;
        logger.log(`Automated CTA scenario switched to ${CtaScenarioKey}`);
      }
      const scenario = assignCtaScenario(data, true, CtaScenarioKey);

      logger.log('Mapping buttons for the following scenario:', scenario);

      const formattedCtas = mapResponse(data, ...scenario);

      if (formattedCtas) {
        // Unsubscribe before updating store
        unsubscribe();

        const dispatchers = await getConnectionVehicleDataDispatchers();
        dispatchers.setCta(formattedCtas);

        logger.log(`Buttons for ${modelSeries} have been added to the store:`, formattedCtas);
      } else {
        logger.log(`No buttons for ${modelSeries} added to the store`);
      }
    } catch (error: any) {
      logger.error(error.message);
    }
  });

export const loadCtaConfiguration: LoadCTAConfigurationFunction = async (
  modelSeries,
  subBrands = [],
  enquiryCta?,
  informedCta?,
  CTAscenario?,
) => {
  const { country, language, apiUri, runMode } = await provideDataFromAemEnvStoreOnce();

  if (!apiUri) {
    logger.log('No apiUri found, skipping CTA service initialization');
    return;
  }

  const deepLinkUrl = makeDeepLinkServiceUrl(apiUri, { runMode, country, language, modelSeries, subBrands });

  const data = await loadDataFromDeeplinkService(deepLinkUrl);

  if (data) {
    logger.log(`Data for ${modelSeries} has been loaded:`, data);

    if (enquiryCta) {
      data[DeeplinkKeys.Enquiry] = enquiryCta;
    }

    if (informedCta) {
      data[DeeplinkKeys.Informed] = informedCta;
    }

    const scenario = assignCtaScenario(data, false, CTAscenario);

    logger.log('Mapping buttons for the following scenario:', scenario);

    const formattedCtas = mapResponse(data, ...scenario);

    if (formattedCtas) {
      logger.log(`Buttons for ${modelSeries} have been returned:`, formattedCtas);
      return formattedCtas;
    } else {
      logger.log(`No buttons for ${modelSeries} returned`);
    }
  }
};
