import { ConnectionAemEnvironmentState } from '@owc/connection-aem-environment';
import { ConnectionVehicleDataState } from '@dh-io-mbmxp/vehicle-data-store-connection';

export type VmdsUrlParams = Pick<ConnectionAemEnvironmentState, 'country' | 'language'> &
  Pick<ConnectionVehicleDataState, 'modelSeries' | 'subBrands' | 'engineConcept'>;

export type formatPriceParams = Pick<ConnectionAemEnvironmentState, 'country' | 'language'> &
  Pick<ConnectionResponse, 'min' | 'max' | 'unit'>;

export enum VmdsHighlightKeys {
  Price = 'PRICE',
}

export enum HighlightGroups {
  Cars = 'PASSENGER_CAR',
  Vans = 'VANS',
}

export interface VmdsHighlightValue {
  min: number;
  max: number;
  unit: string;
  footnoteI18nKey?: string;
}

export interface VmdsResponse {
  highlights: {
    [key in VmdsHighlightKeys]: VmdsHighlightValue;
  };
  i18n: {
    [key: string]: string;
  };
}

export interface ConnectionResponse {
  max: string;
  min: string;
  unit: string;
  pricePrefixLabel: string;
  priceFootnoteLabel?: string;
}

export type LoadPriceDataFunction = (
  modelSeries: string,
  engineConcept: string,
  subBrands?: ConnectionVehicleDataState['subBrands'],
) => Promise<ConnectionResponse | undefined>;
