import { initializeStore } from '@seamless/store';
import { connectionName as aemEnvConnectionName, ConnectionAemEnvironmentState } from '@owc/connection-aem-environment';
import {
  connectionName as vehicleDataConnectionName,
  ConnectionVehicleDataState,
} from '@dh-io-mbmxp/vehicle-data-store-connection';

export const provideDataFromVehicleDataStoreByCallback = (
  callback: (state: ConnectionVehicleDataState, unsubscribe: () => void) => Promise<void | boolean>,
): void => {
  const store = initializeStore();
  const listener = (state: ConnectionVehicleDataState) => {
    const unsubscribe = () => store.unsubscribe(vehicleDataConnectionName, listener);
    callback(state, unsubscribe);
  };
  store.subscribe(vehicleDataConnectionName, listener);
};

export const provideDataFromVehicleDataStoreOnce = (): Promise<ConnectionVehicleDataState> =>
  new Promise((resolve) => {
    const store = initializeStore();
    store.once(vehicleDataConnectionName, resolve);
  });

export const provideDataFromAemEnvStoreOnce = (): Promise<ConnectionAemEnvironmentState> =>
  new Promise((resolve) => {
    const store = initializeStore();
    store.once(aemEnvConnectionName, resolve);
  });
