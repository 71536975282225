import { initializeLogger } from '@seamless/logger';
import { SERVICE_NAMESPACE } from '../../consts';
import { VmdsResponse } from '../../types';

const logger = initializeLogger(SERVICE_NAMESPACE);

export const loadDataFromVmdsService = async (url: string): Promise<VmdsResponse | undefined> => {
  try {
    logger.log(`Sending request to ${url}`);
    const response = await fetch(url);
    const data = await response.json();
    if (response.ok) {
      return data;
    }
  } catch (error) {
    throw Error('Error caused during processing request to VMDS service');
  }
};
