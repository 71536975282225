export enum DeeplinkKeys {
  Configurator = 'CONFIGURATOR',
  OnlineShop = 'ONLINE_SHOP',
  ProductPage = 'PRODUCT_PAGE',
  TestDrive = 'TEST_DRIVE',
  Enquiry = 'ENQUIRY',
  Informed = 'INFORMED',
}

export enum DeeplinkCmsInstance {
  Publish = 'PUBLISHER',
  Author = 'AUTHOR',
}

export enum CTAKeys {
  Primary = 'primary',
  Secondary = 'secondary',
  Shop = 'shop',
  Configurator = 'configurator',
  Info = 'info',
  CarDrive = 'cardrive',
  Enquiry = 'enquiry',
  Informed = 'informed',
}

export enum ScenarioKeys {
  Default = 'default',
  Alternative = 'alternative',
}
