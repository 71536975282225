import { initializeLogger } from '@seamless/logger';
import { SERVICE_NAMESPACE } from './consts';
import { provideDataFromAemEnvStoreOnce, provideDataFromVehicleDataStoreByCallback } from '../../utils';
import { loadDataFromVmdsService, makeVmdsServiceUrl, formatResponse } from './utils';
import { getConnectionVehicleDataDispatchers } from '@dh-io-mbmxp/vehicle-data-store-connection';
import { LoadPriceDataFunction } from './types';

const logger = initializeLogger(SERVICE_NAMESPACE);

export const initializePriceAutomationService = (): void =>
  provideDataFromVehicleDataStoreByCallback(async ({ modelSeries, engineConcept, subBrands }, unsubscribe) => {
    unsubscribe();
    const priceData = await loadPriceData(modelSeries, engineConcept, subBrands);

    if (priceData) {
      const dispatchers = await getConnectionVehicleDataDispatchers();
      dispatchers.setPrice(priceData);
      logger.log(`Price data for ${modelSeries} have been added to the store:`, priceData);
    } else {
      logger.log(`No price data for ${modelSeries} added to the store`);
    }
  });

export const loadPriceData: LoadPriceDataFunction = async (
  modelSeries: string,
  engineConcept: string,
  subBrands = [],
) => {
  const { apiUri, country, language } = await provideDataFromAemEnvStoreOnce();

  if (!apiUri || !modelSeries) {
    logger.log('No apiUri or modelSeries found, skipping price service initialization');
    return;
  }

  try {
    const vmdsServiceUrl = makeVmdsServiceUrl(apiUri, {
      country,
      language,
      modelSeries,
      engineConcept,
      subBrands,
    });

    const data = await loadDataFromVmdsService(vmdsServiceUrl);

    if (!data) {
      logger.log(`No data loaded from VMDS service for ${modelSeries}`);
      return;
    }

    logger.log(`Data for ${modelSeries} has been loaded:`, data);

    const formattedPrice = formatResponse(data, language, country);

    logger.log(`Formatted price for ${modelSeries} have been returned:`, formattedPrice);

    return formattedPrice;
  } catch (error: any) {
    logger.error(error.message);
  }
};
